import { css } from '@emotion/core'
import { findIndex, move, remove, update } from 'ramda'
import React from 'react'
import { Subscribe } from 'unstated'

import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import MoveDownIcon from '@material-ui/icons/ArrowDropDown'
import MoveUpIcon from '@material-ui/icons/ArrowDropUp'
import DeleteIcon from '@material-ui/icons/Delete'
import MoreIcon from '@material-ui/icons/MoreVert'

import Layout from '../../components/layout'
import SavingsContainer from '../../containers/SavingsContainer'

import { SAVINGS_COMPONENTS } from '../../constants'
import withRoot from '../../withRoot'

class ComponentListItem extends React.Component {
  state = { moreAnchorEl: null }

  static getDerivedStateFromProps(props, state) {
    return {
      ...state,
      inputValue: props.onChange ? props.item.name : state.inputValue,
    }
  }

  render() {
    const { item, onChange } = this.props
    const { moreAnchorEl } = this.state
    const showMore = Boolean(moreAnchorEl)
    const InputProps = {}

    if (onChange) {
      InputProps.value = this.state.inputValue
      InputProps.onChange = this.handleChange
    } else {
      InputProps.defaultValue = item.name
    }

    return (
      <ListItem data-testid={`item-${item.name}`}>
        <ListItemText>
          <Input
            fullWidth={true}
            inputProps={{
              onBlur: this.handleBlur,
            }}
            {...InputProps}
            {...this.props.InputProps}
          />
        </ListItemText>
        <ListItemSecondaryAction>
          {this.props.showMore && (
            <IconButton
              color="inherit"
              onClick={this.handleMore}
              aria-label="More"
              aria-owns={showMore ? 'more-menu' : undefined}
              aria-haspopup="true"
            >
              <MoreIcon />
            </IconButton>
          )}
          <Menu
            id="more-menu"
            anchorEl={moreAnchorEl}
            open={showMore}
            onClose={this.handleMoreClose}
          >
            <MenuItem onClick={this.props.onDelete}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
            <MenuItem onClick={this.handleMove(this.props.onMoveUp)}>
              <ListItemIcon>
                <MoveUpIcon />
              </ListItemIcon>
              <ListItemText>Move Up</ListItemText>
            </MenuItem>
            <MenuItem onClick={this.handleMove(this.props.onMoveDown)}>
              <ListItemIcon>
                <MoveDownIcon />
              </ListItemIcon>
              <ListItemText>Move Down</ListItemText>
            </MenuItem>
          </Menu>
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  handleBlur = e => {
    this.props.onValueChange(e.target.value)
  }

  handleChange = e => {
    if (this.props.onChange) {
      this.props.onChange(e.target.value)
    } else {
      this.setState({ inputValue: e.target.value })
    }
  }

  handleMore = e => {
    this.setState({ moreAnchorEl: e.currentTarget })
  }

  handleMoreClose = e => {
    this.setState({ moreAnchorEl: null })
  }

  handleMove = callback => e => {
    this.setState({ moreAnchorEl: null }, () => {
      callback()
    })
  }
}
ComponentListItem.defaultProps = {
  item: null,
  showMore: true,
  InputProps: {},
  onChange: null,
  onValueChange: value => {},
  onDelete: () => {},
  onMoveUp: () => {},
  onMoveDown: () => {},
}

class NewComponentListItem extends React.Component {
  state = { inputValue: '' }

  render() {
    return (
      <ComponentListItem
        {...this.props}
        item={{ type: this.props.type, name: this.state.inputValue }}
        showMore={false}
        onChange={this.handleChange}
        onValueChange={this.handleValueChange}
      />
    )
  }

  handleChange = value => {
    this.setState({ inputValue: value })
  }

  handleValueChange = value => {
    this.setState({ inputValue: '' }, () => {
      this.props.onValueChange(value)
    })
  }
}

class ComponentsEditor extends React.Component {
  render() {
    const { savingsComponents } = this.props

    const ulStyles = css`
      padding: 0;
      list-style-type: none;
    `
    const paperStyle = css`
      position: relative;
      margin-top: 24px;
    `

    const paperHeaderStyle = css`
      margin: 16px;
    `

    return Object.entries(SAVINGS_COMPONENTS).map(
      ([componentName, metadata]) => (
        <Paper
          css={css`
            ${paperStyle};
            border-top: 3px solid ${metadata.color};
          `}
          data-testid={`category-${componentName}`}
        >
          <header css={paperHeaderStyle}>
            <Typography variant="h6" gutterBottom>
              {metadata.heading}
            </Typography>
            <Typography variant="caption">{metadata.description}</Typography>
          </header>
          <List>
            <li key={componentName}>
              <ul css={ulStyles}>
                {savingsComponents[componentName].map(componentItem => (
                  <ComponentListItem
                    key={componentItem.name}
                    item={componentItem}
                    onValueChange={this.handleComponentItemChange(
                      componentName,
                      componentItem
                    )}
                    onDelete={this.handleDelete(componentName, componentItem)}
                    onMoveUp={this.handleComponentItemMoveUp(
                      componentName,
                      componentItem
                    )}
                    onMoveDown={this.handleComponentItemMoveDown(
                      componentName,
                      componentItem
                    )}
                  />
                ))}
                <NewComponentListItem
                  type={componentName}
                  InputProps={{
                    placeholder: 'Add line item...',
                  }}
                  onValueChange={this.handleNewComponentItemChange(
                    componentName
                  )}
                />
              </ul>
            </li>
          </List>
        </Paper>
      )
    )
  }

  handleComponentItemChange = (componentName, componentItem) => value => {
    const componentItems = this.props.savingsComponents[componentName]
    const foundItem = findIndex(
      item => item.name === componentItem.name,
      componentItems
    )

    if (foundItem >= 0) {
      const updatedItems = update(
        foundItem,
        { ...foundItem, name: value },
        componentItems
      )

      this.props.onSave({
        ...this.props.savingsComponents,
        [componentName]: updatedItems,
      })
    }
  }

  handleNewComponentItemChange = componentName => newItemText => {
    const { savingsComponents } = this.props
    const componentItems = savingsComponents[componentName]

    if (newItemText && newItemText.length > 0) {
      this.props.onSave({
        ...savingsComponents,
        [componentName]: [
          ...componentItems,
          { type: componentName, name: newItemText },
        ],
      })
    }
  }

  handleComponentItemMoveUp = (componentName, componentItem) => () => {
    const componentItems = this.props.savingsComponents[componentName]
    const foundItem = findIndex(
      item => item.name === componentItem.name,
      componentItems
    )

    if (foundItem > 0) {
      const updatedItems = move(foundItem, foundItem - 1, componentItems)

      this.props.onSave({
        ...this.props.savingsComponents,
        [componentName]: updatedItems,
      })
    }
  }

  handleComponentItemMoveDown = (componentName, componentItem) => () => {
    const componentItems = this.props.savingsComponents[componentName]
    const foundItem = findIndex(
      item => item.name === componentItem.name,
      componentItems
    )

    if (foundItem >= 0 && foundItem < componentItems.length - 1) {
      const updatedItems = move(foundItem, foundItem + 1, componentItems)

      this.props.onSave({
        ...this.props.savingsComponents,
        [componentName]: updatedItems,
      })
    }
  }

  handleDelete = (componentName, componentItem) => () => {
    const { savingsComponents } = this.props
    const componentItems = savingsComponents[componentName]
    const deleteIndex = findIndex(
      item => item.name === componentItem.name,
      componentItems
    )

    const updatedComponents = remove(
      deleteIndex,
      1,
      savingsComponents[componentName]
    )

    this.props.onSave({
      ...savingsComponents,
      [componentName]: updatedComponents,
    })
  }
}
ComponentsEditor.defaultProps = {
  savingsComponents: {},
  onSave: components => {},
}

const EditPeriodPage = ({ location: { pathname } }) => (
  <Layout title="Period line items" pathname={pathname} upwards="/settings">
    <Subscribe to={[SavingsContainer]}>
      {savingsData => (
        <ComponentsEditor
          savingsComponents={savingsData.state.defaultComponents}
          onSave={savingsData.setDefaultComponents}
        />
      )}
    </Subscribe>
  </Layout>
)

export default withRoot(EditPeriodPage)
